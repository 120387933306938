import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getHomePageVehicles } from '../../services/userServices'
import { RootState } from '../../store/store'

export interface Hvehicles {
  id: number
  model_name: string
  model_description: string
  image_url: string | null
  length_mm: number
  width_mm: number
  height_mm: number
  wheelbase_mm: number
  ground_clearance_mm: number
  braking_distance_m: string
  range_per_charge_km: string
  turning_radius_m: string
  climbing_ability_safe_and_max: string
  load_capacity_kg: number
  colors: string
  body_panels: string
  chassis: string
  batteries: string
  brakes: string
  motor: string
  transaxle: string
  controller: string
  steering: string
  charger: string
  suspension: string
  warranty: string
  created_at: string
  status: number
}

interface UserState {
  Hvehiclesloading: boolean
  Hvehiclesdata: Hvehicles[]
  Hvehicleserror: string | undefined
}

const initialState: UserState = {
  Hvehiclesloading: false,
  Hvehiclesdata: [],
  Hvehicleserror: undefined
}

export const HomeScreenVehicleSlice = createSlice({
  name: 'homeScreenvehicles',
  initialState,
  reducers: {
    resetState: (state) => {
      state.Hvehiclesloading = false
      state.Hvehiclesdata = []
      state.Hvehicleserror = undefined
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getHomePageVehicles.pending, (state) => {
      state.Hvehiclesloading = true
    })
    builder.addCase(
      getHomePageVehicles.fulfilled,
      (state, action: PayloadAction<[]>) => {
        state.Hvehiclesloading = false
        state.Hvehiclesdata = action.payload
      }
    )
    builder.addCase(getHomePageVehicles.rejected, (state, action) => {
      state.Hvehiclesloading = false
      state.Hvehiclesdata = []
      state.Hvehicleserror = action.error.message
    })
  }
})

export const { resetState } = HomeScreenVehicleSlice.actions
export const HomeScreenVehicleSelector = (state: RootState): UserState => state.HomeScreenVehicleSlice
export default HomeScreenVehicleSlice.reducer
