import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { allEstimates } from '../../services/userServices'
import { RootState } from '../../store/store'

export interface Estimates {
  booking_id: number
  estimation_id: number
  technician_id: number
  user_name: string
  service_name: string
  items: [
    {
      item_id: any
      item: string
      cost: number
      quantity: number
    }
  ]
  estimated_price: number
}

interface UserState {
  loading: boolean
  data: Estimates[]
  error: string | undefined
}

const initialState: UserState = {
  loading: false,
  data: [],
  error: undefined
}

export const EstimatesSlice = createSlice({
  name: 'estimates',
  initialState,
  reducers: {
    resetState: (state) => {
      state.loading = false
      state.data = []
      state.error = undefined
    }
  },
  extraReducers: (builder) => {
    builder.addCase(allEstimates.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      allEstimates.fulfilled,
      (state, action: PayloadAction<Estimates[]>) => {
        state.loading = false
        state.data = action.payload
      }
    )
    builder.addCase(allEstimates.rejected, (state, action) => {
      state.loading = false
      state.data = []
      state.error = action.error.message
    })
  }
})

export const { resetState } = EstimatesSlice.actions
export const EstimatesSelector = (state: RootState): UserState => state.EstimatesSlice
export default EstimatesSlice.reducer
