import { configureStore } from '@reduxjs/toolkit'

import addVehicleReducer from '../redux/addVehicleSlice/addVehicle'
import authReducer from '../redux/authSlice/authSlice'
import bookingReducer from '../redux/bookingSlice/bookingSlice'
import customerVehicleReducer from '../redux/customerVehicleSlice/customerVehicleSlice'
import EstimatesSlice from '../redux/EstimatesSlice/EstimatesSlice'
import HomeScreenVehicleSlice from '../redux/homeScreenvehicles/homeScreenVehicle'
import ListOfServiceBookingReducer from '../redux/ListOfServiceBooking/ListOfServiceBookingSlice'
import listServiceItemsSlice from '../redux/listServicesItems/listServicesItems'
import modelReducer from '../redux/modelSlice/modelSlice'
import NotificationSlice from '../redux/NotificationSlice/notificationSlice'
import readNotificationSlice from '../redux/NotificationSlice/readNotificationSlice'
import ProfileDetailsSlice from '../redux/profileSlices/profileDetailsSlice'
import ServiceReportSlice from '../redux/serviceReport/serviceReportSlice'
import technicianListReducer from '../redux/technicianListSlice/technicianListSlice'
import vehicleReducer from '../redux/vehicleSlice/vehicleSlice'

export const store = configureStore({
  reducer: {
    HomeScreenVehicleSlice, ProfileDetailsSlice, ServiceReportSlice, listServiceItemsSlice, authReducer, modelReducer, vehicleReducer, addVehicleReducer, customerVehicleReducer, ListOfServiceBookingReducer, bookingReducer, technicianListReducer, EstimatesSlice, NotificationSlice, readNotificationSlice
  }
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
