import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getNotifications } from '../../services/userServices'
import { RootState } from '../../store/store'

export interface Notification {
  notification_id: number
  notification_status: number
  message: string
  date: string
  read_status: number
}

interface UserState {
  Nloading: boolean
  Ndata: any
  Nerror: string | undefined
  NLength: any
}

const initialState: UserState = {
  Nloading: false,
  Ndata: [],
  Nerror: undefined,
  NLength: null
}

export const NotificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    resetState: (state) => {
      state.Nloading = false
      state.Ndata = []
      state.Nerror = undefined
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state) => {
      state.Nloading = true
    })
    builder.addCase(
      getNotifications.fulfilled,
      (state, action: PayloadAction<[]>) => {
        state.Nloading = false
        state.Ndata = action.payload
        state.NLength = state.Ndata.unreadNotifications
      }
    )
    builder.addCase(getNotifications.rejected, (state, action) => {
      state.Nloading = false
      state.Ndata = []
      state.Nerror = action.error.message
    })
  }
})

export const { resetState } = NotificationSlice.actions
export const NotificationSelector = (state: RootState): UserState => state.NotificationSlice
export default NotificationSlice.reducer
