import { createSlice } from '@reduxjs/toolkit'

import { getItemsList, getServicesList } from '../../services/userServices'
import { RootState } from '../../store/store'

export interface Services {
  id: any
  name: string
  discription: string
  price: any
}

export interface Items {
  GST_Percent: number
  cost: number
  item_id: number
  item_name: string
}

interface UserState {
  Serviceloading: boolean
  ServiceData: Services[]
  Serviceerror: string | undefined
  Itemsloading: boolean
  ItemsData: Items[]
  Itemserror: string | undefined
}

const initialState: UserState = {
  Serviceloading: false,
  ServiceData: [],
  Serviceerror: undefined,
  Itemsloading: false,
  ItemsData: [],
  Itemserror: undefined
}

export const listServiceItemsSlice = createSlice({
  name: 'serviceList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // servicelist cases
    builder.addCase(getServicesList.pending, (state) => {
      state.Serviceloading = true
    })
    builder.addCase(getServicesList.fulfilled, (state, action) => {
      state.Serviceloading = false
      state.ServiceData = action.payload
      state.Serviceerror = undefined
    })
    builder.addCase(getServicesList.rejected, (state, action) => {
      state.Serviceloading = false
      state.Serviceerror = action.error.message
    })
    // itemsList cases
    builder.addCase(getItemsList.pending, (state) => {
      state.Itemsloading = true
    })
    builder.addCase(getItemsList.fulfilled, (state, action) => {
      state.Itemsloading = false
      state.ItemsData = action.payload
      state.Itemserror = undefined
    })
    builder.addCase(getItemsList.rejected, (state, action) => {
      state.Itemsloading = false
      state.Itemserror = action.error.message
    })
  }
})

export const listServiceItemsSelector = (state: RootState): UserState => state.listServiceItemsSlice
export default listServiceItemsSlice.reducer
