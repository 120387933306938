import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { store } from './store/store'
import App from './App'
import reportWebVitals from './reportWebVitals'

import './index.css'
const rootElement = document.getElementById('root')
if (rootElement === null) throw new Error('Failed to find the root element')
const root = createRoot(rootElement)
root.render(
    <BrowserRouter basename='/'>
    <Provider store={store}>
    <App />
    </Provider>
    </BrowserRouter>
)

reportWebVitals()
