import { createSlice } from '@reduxjs/toolkit'

import { login, logout, register, resendotp, updatePassword, verifyOTP } from '../../services/authServices'
import { RootState } from '../../store/store'

// Auth response type
// export interface AuthResponse {
//   id: number
//   username: string
//   email: string
//   phone: string
//   roles: string[]
//   accessToken: string
// }

export interface AuthResponse {
  userId: number
  customerId: number
  username: string
  email: string
  roles: []
  accessToken: string
}

// auth state type
export interface AuthState {
  loading: boolean
  isLoggedIn: boolean
  error: string | undefined
  userName: string
  email: string
  userId: string
  verifyError: any
  resendError: any
}

const stringifyData: string = localStorage.getItem('user') ?? ''
let userName: string = ''
let email: string = ''
let userId: string = ''
let currentToken: string = ''
if (stringifyData !== undefined && stringifyData !== '') {
  const parsedData = JSON.parse(stringifyData)[0]
  currentToken = parsedData.accessToken
  userName = parsedData.username
  email = parsedData.email
  userId = parsedData.customerId
}

// initial auth state
const initialState: AuthState = {
  loading: false,
  isLoggedIn: currentToken.length !== 0,
  error: undefined,
  userName,
  email,
  userId,
  verifyError: '',
  resendError: ''
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: (builder) => {
    // verify cases
    builder.addCase(verifyOTP.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(
      verifyOTP.fulfilled,
      (state) => {
        state.loading = false
        state.error = undefined
        state.verifyError = ''
      }
    )
    builder.addCase(verifyOTP.rejected, (state, action) => {
      state.loading = false
      state.error = undefined
      state.verifyError = action.payload
      state.resendError = ''
    })

    // resendotp cases
    builder.addCase(resendotp.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(
      resendotp.fulfilled,
      (state, action) => {
        state.loading = false
        state.error = undefined
        state.resendError = action.payload
      }
    )
    builder.addCase(resendotp.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload as string
      state.resendError = action.payload
    })

    // register cases
    builder.addCase(register.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(
      register.fulfilled,
      (state) => {
        state.loading = false
        state.error = undefined
      }
    )
    builder.addCase(register.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload as string
    })
    // login cases
    builder.addCase(login.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(
      login.fulfilled,
      (state) => {
        state.loading = false
        state.isLoggedIn = true
        state.error = undefined
      }
    )
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false
      state.isLoggedIn = false
      state.error = action.payload as string
    })

    // update password
    builder.addCase(updatePassword.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(updatePassword.fulfilled, (state) => {
      state.loading = false
      state.error = undefined
    })
    builder.addCase(updatePassword.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload as string
    })

    // logout cases
    builder.addCase(logout.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(
      logout.fulfilled,
      (state) => {
        state.loading = false
        state.isLoggedIn = false
        state.error = undefined
      }
    )
    builder.addCase(logout.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  },
  reducers: {}
})
export const authSelector = (state: RootState): AuthState => state.authReducer
export default authSlice.reducer
