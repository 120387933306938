import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosError, AxiosResponse } from 'axios'

import { authInstance } from '../axiosInstance/instance'
import { AuthResponse } from '../redux/authSlice/authSlice'

// Login credentials type
export interface LoginCredentials {
  email: string
  password: string
}

// register user type
export interface User {
  username: string
  email: string
  password: string
  phone_no: string
  [key: string]: string
}

export interface UpdatePasswordType {
  email: string
  password: string
  new_password: string
}

// register user
export const register = createAsyncThunk('auth/register', async (user: User, { rejectWithValue }) =>
  await authInstance.post<AxiosResponse>(`auth/customersignup`, user)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => {
      if (axios.isAxiosError(error) && (error.response != null)) {
        const e: any = error.response.data
        return rejectWithValue(e.message)
      }
      return rejectWithValue('Unexpected error! try later')
    })
)

// login user
export const login = createAsyncThunk('auth/signin', async (user: LoginCredentials, { rejectWithValue }) =>
  await authInstance.post('auth/signin', user)
    .then((response: AxiosResponse<AuthResponse>) => response.data)
    .catch((error: AxiosError) => {
      if (axios.isAxiosError(error) && (error.response != null)) {
        const e: any = error.response.data
        return rejectWithValue(e.message)
      }
      return rejectWithValue('Unexpected error! try later')
    })
)

// update password

export const updatePassword = createAsyncThunk('auth/updatePassword', async (user: UpdatePasswordType, { rejectWithValue }) =>
  await authInstance.put<UpdatePasswordType>(`auth/updatepassword`, user)
    .then((response: AxiosResponse<UpdatePasswordType>) => response.data)
    .catch((error: AxiosError) => {
      if (axios.isAxiosError(error) && (error.response != null)) {
        const e: any = error.response.data
        return rejectWithValue(e.message)
      }
      return rejectWithValue('Unexpected error! try later')
    })
)

// logout
export const logout = createAsyncThunk('auth/logout', async (_, { rejectWithValue }) => {
  try {
    localStorage.clear()
    return 'Logout successful'
  } catch (error) {
    return rejectWithValue('Logout failed: ')
  }
})

// verify otp
export const verifyOTP = createAsyncThunk('auth/verifyOTP', async (body: any, { rejectWithValue }) => {
  try {
    const response = await authInstance.post<any>('auth/verify_email', body)
    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && (error.response !== null)) {
      const errorMessage: any = error?.response?.data
      return rejectWithValue(errorMessage)
    }
    throw error
  }
})

// Resend OTP
export const resendotp = createAsyncThunk('auth/resendotp', async (email: string, { rejectWithValue }) => {
  try {
    const response = await authInstance.post<any>(`auth/send_otp?email=${email}`)
    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && (error.response !== null)) {
      const errorMessage: any = error?.response?.data
      return rejectWithValue(errorMessage)
    }
    throw error
  }
})
