import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getallmodels } from '../../services/userServices'
import { RootState } from '../../store/store'

interface Model {
  name: string
  id: number
  image_url: string
}

interface SubCategory {
  name: string
  id: number
  category: string
  image_url: string
  models: Model[]
}

interface Data {
  id: number
  name: string
  image_url: string
  sub_categories: SubCategory[]
}

// State type for the Redux slice
interface UserState {
  loading: boolean
  data: Data[]
  error: string | undefined
}

// Initial state for the Redux slice
const initialState: UserState = {
  loading: false,
  data: [],
  error: undefined
}

// Create a Redux slice
export const vehicleSlice = createSlice({
  name: 'vehicleModels',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getallmodels.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getallmodels.fulfilled,
      (state, action: PayloadAction<Data[]>) => {
        state.loading = false
        state.data = action.payload
      }
    )
    builder.addCase(getallmodels.rejected, (state, action) => {
      state.loading = false
      state.data = []
      state.error = action.error.message
    })
  }
})

// Selectors
export const vehicleSelector = (state: RootState): UserState => state.vehicleReducer
export default vehicleSlice.reducer
