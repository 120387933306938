// user routes
import { lazy } from 'react'
const Home = lazy(async () => await import('../screens/userScreen/Home'))
const Package = lazy(async () => await import('../screens/userScreen/Package'))
const MemberShip = lazy(async () => await import('../screens/userScreen/MemberShip'))
const ListOfServiceBookings = lazy(async () => await import('../screens/userScreen/ListOfServiceBookings'))
const Account = lazy(async () => await import('../screens/userScreen/Account'))
const AddVehicle = lazy(async () => await import('../screens/userScreen/AddVehicle'))
const Vehicle = lazy(async () => await import('../screens/userScreen/Vehicle'))
const ServiceBooking = lazy(async () => await import('../screens/userScreen/ServiceBooking'))
const Profile = lazy(async () => await import('../screens/userScreen/Profile'))
const Notifications = lazy(async () => await import('../components/notification/notification'))
const MyVehicles = lazy(async () => await import('../screens/userScreen/MyVehicles'))
const Orders = lazy(async () => await import('../screens/userScreen/Orders'))
const RentVehicle = lazy(async () => await import('../screens/userScreen/RentVehicle'))
const PageNotFound = lazy(async () => await import('../screens/userScreen/NotFound'))
const Estimates = lazy(async () => await import('../screens/userScreen/Estimates'))
const Inprogress = lazy(async () => await import('../screens/userScreen/ServicesInProgress'))

export default { Notifications, Home, Package, MemberShip, ListOfServiceBookings, Account, AddVehicle, Vehicle, ServiceBooking, Profile, MyVehicles, Orders, RentVehicle, PageNotFound, Estimates, Inprogress }
