import { createSlice } from '@reduxjs/toolkit'

import { getServiceReport } from '../../services/userServices'
import { RootState } from '../../store/store'

export interface ServiceReportInterface {
  booking_id: string
  bookingrefid: string
  customerid: string
  customer_name: string
  technid: string
  technician_name: string
  bookingdate: string
  vehicle_id: string
  vehicle_cat_name: string
  vehicle_subcat_name: string
  model_name: string
  estimation_id: string
  estimated_date: string
  estimated_price: string
  service_id: string
  service_name: string
  service_ended: string
  service_started: string
  items: [
    {
      item_id: string
      item: string
      cost: string
      quantity: string
      started_at: string
      ended_at: string
    },
  ]
  stage_images: [
    {
      stage: string
      images: []
    }
  ]
  total_cost: string
  bookingstatus: string
}

interface UserState {
  ServiceReportloading: boolean
  ServiceReportdata: ServiceReportInterface[]
  ServiceReporterror: any
}

const initialState: UserState = {
  ServiceReportloading: false,
  ServiceReportdata: [],
  ServiceReporterror: ''
}

export const ServiceReportSlice = createSlice({
  name: 'serviceReport',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getServiceReport.pending, (state) => {
      state.ServiceReportloading = true
    })
    builder.addCase(getServiceReport.fulfilled, (state, action) => {
      state.ServiceReportloading = false
      state.ServiceReportdata = action.payload
      state.ServiceReporterror = undefined
    })
    builder.addCase(getServiceReport.rejected, (state, action) => {
      state.ServiceReportloading = false
      state.ServiceReporterror = action.error.message
    })
  }
})

export const ServiceReportSelector = (state: RootState): UserState => state.ServiceReportSlice
export default ServiceReportSlice.reducer
