import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getCustomerVehicle } from '../../services/userServices'
import { RootState } from '../../store/store'

export interface CustomerVehicle {
  customer_vehicleid: number
  company_name: string
  contact_person: string
  contact_number: string
  purchase_date: string
  vehicle_category: string | null
  image: string
  vehicle_cat_id: number
  vehicle_subcatid: number
  modelid: number
  model_name: string
  vehicle_cat_name: string
  vehicle_subcat_name: string
  customerid: number
  AMC_status: any
  invoiceno: string
  sub_category: string
  category: string
  model: string
  file_details: any
}
// State type for the Redux slice
interface UserState {
  loading: boolean
  data: CustomerVehicle[]
  error: string | undefined
}

// Initial state for the Redux slice
const initialState: UserState = {
  loading: false,
  data: [],
  error: undefined
}

// Create a Redux slice
export const customerVehicleSlice = createSlice({
  name: 'customerVehicle',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCustomerVehicle.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getCustomerVehicle.fulfilled,
      (state, action: PayloadAction<CustomerVehicle[]>) => {
        state.loading = false
        state.data = action.payload
      }
    )
    builder.addCase(getCustomerVehicle.rejected, (state, action) => {
      state.loading = false
      state.data = []
      state.error = action.error.message
    })
  }
})

// Selectors
export const customerVehicleSelector = (state: RootState): UserState => state.customerVehicleReducer
export default customerVehicleSlice.reducer
