import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { allservicebooking } from '../../services/userServices'
import { RootState } from '../../store/store'

export interface ListOfServiceBooking {
  bookingid: number
  slot_name: string
  Description: string
  technid: number
  technician_name: string
  model_name: string
  vehicle_cat_name: string
  vehicle_subcat_name: string
  bookingdate: any
  bookingstatus: string
  priority: string
  customerid: number
  AMC_status: any
  invoiceno: string
  formattedDate: any
  created_date: string
  createdDate: string
}
// State type for the Redux slice
interface UserState {
  loading: boolean
  data: ListOfServiceBooking[]
  error: string | undefined
}

// Initial state for the Redux slice
const initialState: UserState = {
  loading: false,
  data: [],
  error: undefined
}

// Create a Redux slice
export const ListOfServiceBookingSlice = createSlice({
  name: 'bookedServices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(allservicebooking.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      allservicebooking.fulfilled,
      (state, action: PayloadAction<ListOfServiceBooking[]>) => {
        state.loading = false
        state.data = action.payload
      }
    )
    builder.addCase(allservicebooking.rejected, (state, action) => {
      state.loading = false
      state.data = []
      state.error = action.error.message
    })
  }
})

// Selectors
export const ListOfServiceBookingSelector = (state: RootState): UserState => state.ListOfServiceBookingReducer
export default ListOfServiceBookingSlice.reducer
