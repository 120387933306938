import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import SkeletonLoader from './components/loaders/SkeletonLoader'
import { authSelector } from './redux/authSlice/authSlice'
import authRoute from './routes/authRoute'
import userRoute from './routes/userRoute'
import { useAppSelector } from './store/hooks'

interface ValidateRouteProps {
  element: React.ReactNode
  isAuthenticated: boolean
  fallbackPath?: string
}

const ValidateRoute: React.FC<ValidateRouteProps> = ({ element, isAuthenticated, fallbackPath = '/login' }) => {
  if (!React.isValidElement(element)) {
    return null
  }

  return isAuthenticated ? element : <Navigate to={fallbackPath} replace />
}

function App (): JSX.Element {
  const { isLoggedIn } = useAppSelector(authSelector)
  const isAuthenticated: boolean = isLoggedIn
  return (
    <Suspense fallback={<SkeletonLoader />} >
      <Routes>
        {/* auth route */}
        <Route path="/login" element={<authRoute.Login />} />
        <Route path="/signup" element={<authRoute.SignUp />} />
        <Route path="/resetPassword" element={<authRoute.ResetPassword />} />
        <Route path="*" element={<userRoute.PageNotFound />} />
        <Route path="/" element={<userRoute.Home />} />

        {/* secured route */}
        <Route path="/package" element={<ValidateRoute element={<userRoute.Package />} isAuthenticated={true} />} />
        <Route path="/membership" element={<ValidateRoute element={<userRoute.MemberShip />} isAuthenticated={isAuthenticated} />} />
        <Route path="/ListOfServiceBookings" element={<ValidateRoute element={<userRoute.ListOfServiceBookings />} isAuthenticated={isAuthenticated} />} />
        <Route path="/account" element={<ValidateRoute element={<userRoute.Account />} isAuthenticated={isAuthenticated} />} />
        <Route path="/addVehicle" element={<ValidateRoute element={<userRoute.AddVehicle />} isAuthenticated={isAuthenticated} />} />
        <Route path="/vehicle/:category" element={<ValidateRoute element={<userRoute.Vehicle />} isAuthenticated={isAuthenticated} />} />
        <Route path="/serviceBooking" element={<ValidateRoute element={<userRoute.ServiceBooking />} isAuthenticated={isAuthenticated} />} />
        <Route path="/orders" element={<ValidateRoute element={<userRoute.Orders />} isAuthenticated={isAuthenticated} />} />
        <Route path="/vehicles" element={<ValidateRoute element={<userRoute.MyVehicles />} isAuthenticated={isAuthenticated} />} />
        <Route path="/rentVehicle" element={<ValidateRoute element={<userRoute.RentVehicle />} isAuthenticated={isAuthenticated} />} />
        <Route path="/profile" element={<ValidateRoute element={<userRoute.Profile />} isAuthenticated={isAuthenticated} />} />
        <Route path="/Notifications" element={<ValidateRoute element={<userRoute.Notifications />} isAuthenticated={isAuthenticated} />} />
        <Route path="/Estimates" element={<ValidateRoute element={<userRoute.Estimates />} isAuthenticated={isAuthenticated} />} />
        <Route path="/ServicesInprogress" element={<ValidateRoute element={<userRoute.Inprogress />} isAuthenticated={isAuthenticated} />} />
      </Routes>
    </Suspense>
  )
}

export default App
