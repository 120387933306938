import { createSlice } from '@reduxjs/toolkit'

import { getProfileDetails } from '../../services/userServices'
import { RootState } from '../../store/store'

export interface profileDetailsInterface {
  id: number
  customer_id: number
  username: string
  first_name: string
  last_name: string
  gender: null
  email: string
  phone_no: string
  alternative_number: string
  profile_image: string | null
  address_1: string
  address_2: string
  landmark: null
  locality: null
  city: number
  country: null
  pincode: string
  role: string
}

interface UserState {
  ProfileDetailsloading: boolean
  ProfileDetailsdata: profileDetailsInterface[]
  ProfileDetailserror: any
}

const initialState: UserState = {
  ProfileDetailsloading: false,
  ProfileDetailsdata: [],
  ProfileDetailserror: ''
}

export const ProfileDetailsSlice = createSlice({
  name: 'profileDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfileDetails.pending, (state) => {
      state.ProfileDetailsloading = true
    })
    builder.addCase(getProfileDetails.fulfilled, (state, action) => {
      state.ProfileDetailsloading = false
      state.ProfileDetailsdata = action.payload
      state.ProfileDetailserror = undefined
    })
    builder.addCase(getProfileDetails.rejected, (state, action) => {
      state.ProfileDetailsloading = false
      state.ProfileDetailserror = action.error.message
    })
  }
})

export const ProfileDetailsSelector = (state: RootState): UserState => state.ProfileDetailsSlice
export default ProfileDetailsSlice.reducer
