import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosError, AxiosResponse } from 'axios'

import { authInstance, dataInstance } from '../axiosInstance/instance'

export interface VehicleDataType {
  customerid: string
  vehicle_cat_id: number
  vehicle_subcatid: number
  modelid: string
  company_name: string
  contact_person: string
  contact_number: string
  purchase_date: string
  invoiceno: string
  image?: File | string
}
export interface BookingDataType {
  customerid: string
  slottype: string
  bookingdate: string
  technid: string
  vehicle_id: number
  images?: File | any
  video?: File | any
  comments: string
}

// get allmodels
export const getallmodels = createAsyncThunk('getallmodels', async (_, { rejectWithValue }) =>
  await dataInstance.get<AxiosResponse>(`common/models`)
    .then((response: AxiosResponse<AxiosResponse>) => response.data.data)
    .catch((error: AxiosError) => {
      if (axios.isAxiosError(error) && (error.response != null)) {
        const e: any = error.response.data
        return rejectWithValue(e.message)
      }
      return rejectWithValue('Unexpected error! try later')
    })
)

// add vehicle
export const addVehicle = createAsyncThunk('addVehicle', async (vehicledata: VehicleDataType, { rejectWithValue }) =>
  await dataInstance.post<AxiosResponse>(`customer/add_vehicle`, vehicledata)
    .then((response: AxiosResponse<AxiosResponse>) => response.data.data)
    .catch((error: AxiosError) => {
      if (axios.isAxiosError(error) && (error.response != null)) {
        const e: any = error.response.data
        return rejectWithValue(e.message)
      }
      return rejectWithValue('Unexpected error! try later')
    })
)

export const addVehicleImg = async (formDataToSend: any, customerId: any): Promise<any> => {
  const token: string = JSON.parse(`${localStorage.getItem('user')}`)[0].accessToken
  const headers = new Headers()
  headers.append('x-access-token', token)
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  fetch(`${process.env.REACT_APP_API_BASE_URL}/customer/add_vehicle?customer_id=${customerId}`, {
    method: 'POST',
    headers,
    body: formDataToSend
  }).then(async response => {
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    return await response.json()
  })
}

// getcustomer vehicle
export const getCustomerVehicle = createAsyncThunk('getCustomerVehicle', async (id: string, { rejectWithValue }) =>
  await dataInstance.get<AxiosResponse>(`customer/vehicles?customer_id=${id}`)
    .then((response: AxiosResponse<AxiosResponse>) => response.data.data)
    .catch((error: AxiosError) => {
      if (axios.isAxiosError(error) && (error.response != null)) {
        const e: any = error.response.data
        return rejectWithValue(e.message)
      }
      return rejectWithValue('Unexpected error! try later')
    })
)
// getcustomer vehicle
export const getAlltechnicians = createAsyncThunk('getAlltechnicians', async (_, { rejectWithValue }) =>
  await dataInstance.get<AxiosResponse>(`customer/technicians`)
    .then((response: AxiosResponse<AxiosResponse>) => response.data.data)
    .catch((error: AxiosError) => {
      if (axios.isAxiosError(error) && (error.response != null)) {
        const e: any = error.response.data
        return rejectWithValue(e.message)
      }
      return rejectWithValue('Unexpected error! try later')
    })
)
// get list of servicebooking
export const allservicebooking = createAsyncThunk('allservicebooking', async (id: string, { rejectWithValue }) =>
  await dataInstance.get<AxiosResponse>(`/customer/all_service_bookings?customer_id=${id}`)
    .then((response: AxiosResponse<AxiosResponse>) => response.data.data)
    .catch((error: AxiosError) => {
      if (axios.isAxiosError(error) && (error.response != null)) {
        const e: any = error.response.data
        return rejectWithValue(e.message)
      }
      return rejectWithValue('Unexpected error! try later')
    })
)
// get list of servicebooking
export const confirmRejectbooking = createAsyncThunk('confirmbooking', async (data: any, { rejectWithValue }) =>
  await dataInstance.put<AxiosResponse>(`customer/confirm_or_reject_job?booking_id=${data.id}&status=${data.status}`)
    .then((response: AxiosResponse<AxiosResponse>) => response.data.data)
    .catch((error: AxiosError) => {
      if (axios.isAxiosError(error) && (error.response != null)) {
        const e: any = error.response.data
        return rejectWithValue(e.message)
      }
      return rejectWithValue('Unexpected error! try later')
    })
)
// get list of servicebooking
export const rejectbooking = createAsyncThunk('rejctbooking', async (id: string, { rejectWithValue }) =>
  await dataInstance.put<AxiosResponse>(`/customer/close_job?booking_id=${id}`)
    .then((response: AxiosResponse<AxiosResponse>) => response.data.data)
    .catch((error: AxiosError) => {
      if (axios.isAxiosError(error) && (error.response != null)) {
        const e: any = error.response.data
        return rejectWithValue(e.message)
      }
      return rejectWithValue('Unexpected error! try later')
    })
)

// get all estimates
export const allEstimates = createAsyncThunk('estimates', async (id: string, { rejectWithValue }) =>
  await dataInstance.get<AxiosResponse>(`/customer/all_estimates?customer_id=${id}`)
    .then((response: AxiosResponse<AxiosResponse>) => response.data.data)
    .catch((error: AxiosError) => {
      if (axios.isAxiosError(error) && (error.response != null)) {
        const e: any = error.response.data
        return rejectWithValue(e.message)
      }
      return rejectWithValue('Unexpected error! try later')
    })
)

export const getNotifications = createAsyncThunk('notifications', async (id: string, { rejectWithValue }) =>
  await dataInstance.get<AxiosResponse>(`/customer/customer_notifications?customer_id=${id}`)
    .then((response: AxiosResponse<AxiosResponse>) => {
      let count = 0
      response.data.data.map((each: any) => {
        if (each.customer_read_status === 0) count++
        return each
      })
      // localStorage.setItem('unreadNotifications', String(count))
      response.data.data.unreadNotifications = count
      return response.data.data
    })
    .catch((error: AxiosError) => {
      if (axios.isAxiosError(error) && (error.response != null)) {
        const e: any = error.response.data
        return rejectWithValue(e.message)
      }
      return rejectWithValue('Unexpected error! try later')
    })
)

export const readNotification = createAsyncThunk('readNotification', async (id: any, { rejectWithValue }) => {
  await dataInstance.patch<AxiosResponse>(`/customer/read_notification?notification_id=${id}`)
    .then((response: AxiosResponse<AxiosResponse>) => {
      return response.data
    })
    .catch((error: AxiosError) => {
      if (axios.isAxiosError(error) && (error.response != null)) {
        const e: any = error.response.data
        return rejectWithValue(e.message)
      }
      return rejectWithValue('Unexpected error! try later')
    })
})

export const servicebooking = createAsyncThunk('servicebooking', async (formDataWithFiles: any) => {
  const token: string = JSON.parse(`${localStorage.getItem('user')}`)[0].accessToken
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/customer/servicebooking`, formDataWithFiles, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-access-token': token
      }
    })
    return response.data
  } catch (error) {
    return error
  }
})

export const getServicesList = createAsyncThunk('serviceList', async (_, { rejectWithValue }) =>
  await dataInstance.get<AxiosResponse>(`/common/services`)
    .then((response: AxiosResponse<AxiosResponse>) => response.data.data)
    .catch((error: AxiosError) => {
      if (axios.isAxiosError(error) && (error.response != null)) {
        const e: any = error.response.data
        return rejectWithValue(e.message)
      }
      return rejectWithValue('Unexpected error! try later')
    })
)

export const getItemsList = createAsyncThunk('itemsList', async (_, { rejectWithValue }) =>
  await dataInstance.get<AxiosResponse>(`/common/items`)
    .then((response: AxiosResponse<AxiosResponse>) => response.data.data)
    .catch((error: AxiosError) => {
      if (axios.isAxiosError(error) && (error.response != null)) {
        const e: any = error.response.data
        return rejectWithValue(e.message)
      }
      return rejectWithValue('Unexpected error! try later')
    })
)

export const getServiceReport = createAsyncThunk('serviceReport', async (data: any, { rejectWithValue }) =>
  await dataInstance.get<AxiosResponse>(`/customer/service_report?booking_id=${data.bookingId}&customer_id=${data.userId}`)
    .then((response: AxiosResponse<AxiosResponse>) => response.data.data)
    .catch((error: AxiosError) => {
      if (axios.isAxiosError(error) && (error.response != null)) {
        const e: any = error.response.data
        return rejectWithValue(e.message)
      }
      return rejectWithValue('Unexpected error! try later')
    })
)

export const getProfileDetails = createAsyncThunk('profileDetails', async (_, { rejectWithValue }) =>
  await dataInstance.get<AxiosResponse>(`/customer/customer_profile?customer_id=1`)
    .then((res: AxiosResponse<AxiosResponse>) => res.data.data)
    .catch((error: AxiosError) => {
      if (axios.isAxiosError(error) && (error.response != null)) {
        const e: any = error.response.data
        return rejectWithValue(e.message)
      }
      return rejectWithValue('Unexpected error! try later')
    })
)

export const sendFcmToken = createAsyncThunk('sendFcmToken', async (data: any, { rejectWithValue }) =>
  await dataInstance.patch<AxiosResponse>(`/customer/store_fcm_token?user_id=${data.userId}`, data.body)
    .then((response: AxiosResponse<AxiosResponse>) => response.data.data)
    .catch((error: AxiosError) => {
      if (axios.isAxiosError(error) && (error.response != null)) {
        const e: any = error.response.data
        return rejectWithValue(e.message)
      }
      return rejectWithValue('Unexpected error! try later')
    })
)

export const getHomePageVehicles = createAsyncThunk('homeScreenvehicles', async (_, { rejectWithValue }) =>
  await authInstance.get<AxiosResponse>(`/customer/home_page_display_vehicles`)
    .then((res: AxiosResponse<AxiosResponse>) => res.data.data)
    .catch((error: AxiosError) => {
      if (axios.isAxiosError(error) && (error.response != null)) {
        const e: any = error.response.data
        return rejectWithValue(e.message)
      }
      return rejectWithValue('Unexpected error! try later')
    })
)

export const closeBooking = createAsyncThunk('closeBooking', async (bookingId: number, { rejectWithValue }) =>
  await dataInstance.put<AxiosResponse>(`/customer/close_job?booking_id=${bookingId}`)
    .then((res: AxiosResponse<AxiosResponse>) => res.data.data)
    .catch((error: AxiosError) => {
      if (axios.isAxiosError(error) && (error.response != null)) {
        const e: any = error.response.data
        return rejectWithValue(e.message)
      }
      return rejectWithValue('Unexpected error! try later')
    })
)
