import { createSlice } from '@reduxjs/toolkit'

import { RootState } from '../../store/store'

// state type
interface UserState {
  error: string | undefined
  isModalOpen: boolean
}

// initial state
const initialState: UserState = {
  error: undefined,
  isModalOpen: false
}

export const userSlice = createSlice({
  name: 'model',
  initialState,
  reducers: {
    openModal: (state) => {
      state.isModalOpen = true
    },
    closeModal: (state) => {
      state.isModalOpen = false
    }
  }
})

export const { openModal, closeModal } = userSlice.actions

export const modelSelector = (state: RootState): UserState => state.modelReducer

export default userSlice.reducer
