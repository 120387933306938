import React from 'react'

const SkeletonLoader: React.FC = () => {
  const skeletonLines = Array.from({ length: 24 }, (_, index) => (
    <div key={index} className={lineStyle}></div>
  ))
  return <div className="skeleton-loader min-h-full bg-gradient-to-r from-gray-100 to-gray-200 p-4 rounded animate-pulse">
  {skeletonLines}
  </div>
}

const lineStyle: string = 'skeleton-loader-line h-6 bg-gradient-to-r from-gray-200 to-gray-300 mb-2'
export default SkeletonLoader
