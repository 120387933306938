import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getAlltechnicians } from '../../services/userServices'
import { RootState } from '../../store/store'

export interface Technician {
  technician_id: number
  technician_name: string
  experience: number
  rating: any
  src: any
}

// State type for the Redux slice
interface UserState {
  loading: boolean
  technician: Technician[]
  error: string | undefined
}

// Initial state for the Redux slice
const initialState: UserState = {
  loading: false,
  technician: [],
  error: undefined
}

// Create a Redux slice
export const technicianSlice = createSlice({
  name: 'technician',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAlltechnicians.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getAlltechnicians.fulfilled,
      (state, action: PayloadAction<Technician[]>) => {
        state.loading = false
        state.technician = action.payload
      }
    )
    builder.addCase(getAlltechnicians.rejected, (state, action) => {
      state.loading = false
      state.technician = []
      state.error = action.error.message
    })
  }
})

// Selectors
export const technicianSelector = (state: RootState): UserState => state.technicianListReducer
export default technicianSlice.reducer
