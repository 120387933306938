import axios from 'axios'

import { AuthResponse } from '../redux/authSlice/authSlice'

// auth api instance
const authInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
})

// data api instance
const dataInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
})

// auth api response interceptor
authInstance.interceptors.response.use(function (response) {
  const token = response.data.accessToken
  if (token !== null && token !== undefined) {
    localStorage.setItem('user', JSON.stringify([response.data]))
    localStorage.setItem('customerId', JSON.stringify(response.data.customerId))
  }
  return response
}, async function (error) {
  return await Promise.reject(error)
})

// data api's request interceptor
dataInstance.interceptors.request.use(function (config) {
  const stringifyData = localStorage.getItem('user')
  let jsonData: AuthResponse[]
  if (stringifyData !== null && stringifyData !== undefined) {
    jsonData = JSON.parse(stringifyData)
    // config.headers.Authorization = `Bearer ${jsonData[0].token}`
    config.headers['x-access-token'] = jsonData[0].accessToken
  }
  config.headers['Content-Type'] = 'application/json'
  return config
}, async function (error) {
  return await Promise.reject(error)
})

export { authInstance, dataInstance }
