import { createSlice } from '@reduxjs/toolkit'

import { readNotification } from '../../services/userServices'
import { RootState } from '../../store/store'

// State type for the Redux slice
interface UserState {
  loading: boolean
  error: string | undefined
}

// Initial state for the Redux slice
const initialState: UserState = {
  loading: false,
  error: undefined
}

// Create a Redux slice
export const readNotificationSlice = createSlice({
  name: 'readNotification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(readNotification.pending, (state) => {
      state.loading = true
      state.error = undefined // Clear error on pending
    })
    builder.addCase(
      readNotification.fulfilled,
      (state) => {
        state.loading = false
        state.error = undefined
      }
    )
    builder.addCase(readNotification.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

// Selectors
export const readNotificationSelector = (state: RootState): UserState => state.readNotificationSlice

export default readNotificationSlice.reducer
